.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.scrollTopBtn {
  right: 56px;
  bottom: 142px;
  position: absolute;
}
.ant-collapse-header {
  padding: 0px !important;
}
.ant-tree-switcher {
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-tree-treenode {
  width: 100%;
  position: relative;
}
.ant-tree-node-content-wrapper {
  position: unset !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 1280px) {
  .md\:container {
    max-width: 1110px !important;
  }
  .container {
    max-width: 1110px !important;
  }
}
.h-min-100 {
  min-height: 100vh;
  padding-bottom: 180px;
}


.CategoryDataComp .flex-rev, .CategoryDataComp.flex-rev .ant-tree-treenode {
  flex-direction: row-reverse !important;
}

.ant-tree-iconEle.ant-tree-icon__customize{
  display: none !important;
}
.ant-tree-treenode::before{
  background-color: transparent !important;
}
.ant-tree-treenode:hover::before{
  background-color: transparent !important;
}
.anticon.anticon-caret-down{
  color: black !important;
} 