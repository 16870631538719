.headerContainer {
  min-height: 660px;
  background-position: center;
  background-size: 100% 100%;
  padding-bottom: 40px;
  /* background-repeat: round; */
}
@media (min-width: 1500px){
  .headerContainer {
    aspect-ratio: 2.18;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  /* background-color: #f9f9f9; */
  min-width: 182px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  left: -115%;
  margin: 10px;
  top: 85%;

}

.dropdown:hover .dropdown-content, .dropdownArrow:hover.dropdown-content {
  display: block;
}
.dropdown-content:hover{
  display: block;

}
.polygonArrow{
  position: absolute;
  top: -5%;
  left: 45%;
}